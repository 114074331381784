* {
    font-size: 1.5rem;
}

html, body {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    margin: 0;
}

.panel button {
    flex: 1;
}

.stopButton {
    background: red;
    color: white;
    border: none;
    padding: 1rem 0.5rem;
}

.startButton {
    background: green;
    color: white;
    border: none;
    padding: 1rem 0.5rem;
}